import logo from "./logo.svg";
import "./App.css";
import {useEffect, useState} from "react";

function redirect_me(url) {
  window.open(url, "_self");
}
function App() {
  const [sites, setSites] = useState("");
  const refresh_groupings = () => {
    fetch("http://secondbug.world/ss", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Origin: "",
        Host: "localhost",
      },
      mode: "cors",
    })
      .then((r) => {
        return r.text();
      })
      .then((rx) => {
        let rxr = rx.replace(/,/g, "\n").replace(/ /g, "\n").replace(" ", "\n");
        let splitted = rxr.split("\n").join(",").split(" ").join(",").split(",");
        setSites(splitted);
        console.log(rx);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    //get the list of sites that are active atm
    const months = ["January", "February", "March", "April", "May", "June", "July"];
    const random = Math.floor(Math.random() * months.length);
    console.log(random, months[random]);

    refresh_groupings();
    if (sites !== "") {
      //pick at random one of those sites
      const r2 = Math.floor(Math.random() * sites.length);
      console.log(r2, sites[r2]);
      //redirect to that new url
      redirect_me(sites[r2]);
    }

    // console.log("testing redirection");
    // redirect_me("https://www.google.com");
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
